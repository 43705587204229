import _ from 'lodash';
import qs from 'query-string';
import moment from 'moment';
import numeral from 'numeral';
import {QUERY_PARAMS} from '../constants/questions';
import {BASE_URL} from '../constants/config';
import {LimitedCoachingSSOProviders, CoachingBannerSSOProviders} from '../../../lib/constants/sso';
import {
  LEADING_JOIN_TIME,
  TRAILING_MATCH_TIMES,
  InterviewTypes,
  InterviewModes,
  AIUniversityAllowList,
} from '../../../lib/constants/duet';

export const absoluteURLMatcher = new RegExp('^(?:[a-z]+:)?//', 'i');
export const dangerousURLMatcher = new RegExp('^javascript:', 'i');

export const isAbsoluteURL = (str) => absoluteURLMatcher.test(str);
export const isDangerousURL = (str) => dangerousURLMatcher.test(str);
export const isExternalURL = (str) => isAbsoluteURL(str) && !str.startsWith(BASE_URL);
export const isRelativeURL = (str) => (
  typeof str === 'string'
  && !isAbsoluteURL(str)
  && !isDangerousURL(str)
);

export const createURLWithParams = (root, params, options = {arrayFormat: 'index'}) => (
  qs.stringifyUrl({url: root, query: _.omitBy(params, _.isNil)}, options)
);

export const parseFilterParamsFromSearch = (search) => _.pick(
  // {arrayFormat: 'comma'} is used here, as opposed to 'index' like in most
  // places, so that URLs previously indexed by Google still work correctly
  qs.parse(search, {arrayFormat: 'comma'}), QUERY_PARAMS,
);

export const getReferrer = () => (typeof document !== 'undefined' ? document.referrer : null);

export const getFirstValue = (val) => {
  if (!val) return null;
  return Array.isArray(val) ? val[0] : val;
};

export const tagsToQuestionListTitle = (tags, params, succinct = false) => {
  if (!tags || !params) return '';

  const {
    company, role, type, sortBy, filterBy, difficulty,
  } = params;
  let title = '';

  // TODO: Handle multiple companies, roles, categories better
  const companyTag = _.find(tags.company, ['id', getFirstValue(company)]);
  const categoryTag = _.find(tags.type, ['id', getFirstValue(type)]);
  const roleTag = _.find(tags.role, ['id', getFirstValue(role)]);

  const validDifficulties = ['Hard', 'Medium', 'Easy'];
  const sanitizedDifficulty = _.includes(validDifficulties, difficulty) ? difficulty : '';

  if ((companyTag || categoryTag || roleTag) && !succinct) {
    title += sortBy === 'newest' ? 'Recent' : 'Top';
  }

  if (companyTag) title += ` ${companyTag.name}`;

  if (roleTag && (!categoryTag || !companyTag)) title += ` ${roleTag.name}`;

  if (categoryTag) {
    // special case: do not add ml category tag if role is already ml-engineer
    if (!(categoryTag.id === 'machine-learning' && roleTag?.id === 'ml-engineer')) {
      title += ` ${categoryTag.name}`;
    }
  }

  // only mention difficulty level of coding question when no better title
  if (!title && filterBy === 'codeEditor') {
    title = `${sanitizedDifficulty ? `${sanitizedDifficulty} ` : ''}Coding`;
  }

  if (!succinct) title += ' Interview';

  return `${title} Questions`;
};

export const tagsToQuestionListDescription = (tags, params, numResults = 0) => {
  if (!tags || !params) return '';

  const {company, role, type} = params;
  const tagArray = _.compact([
    _.find(tags.company, ['id', company]),
    _.find(tags.type, ['id', type]),
    _.find(tags.role, ['id', role]),
  ]).map((tag) => (tag.type !== 'company' ? tag.name.toLowerCase() : tag.name));

  return `Review this list of ${numResults > 4 ? numResults.toLocaleString() : ''} ${tagArray.join(' ')} interview questions and answers verified by hiring managers and candidates.`;
};

/**
 * Takes an array of role tags and an array of role category
 * tags and groups roles by their parent role category. Output
 * can then be passed as the options to our DropdownInput component
*/
export const roleToRoleCategoryOptions = (roles, roleCategories) => {
  const roleOptions = (roles || [])
    .map((role) => ({...role, label: role.name, value: role.id}));
  const rolesByCategory = _.groupBy(roleOptions, 'parent_tag');
  return _(roleCategories || [])
    .filter((category) => rolesByCategory[category.id])
    .map((category) => ({label: category.name, options: rolesByCategory[category.id]}))
    .value();
};

/**
 * Formats a tag such that it can be passed to options in DropdownInput or MultiSelect
 */
export const tagToOption = (tag) => ({value: tag.id, label: tag.name});

export const getEnglishTime = (timestamp) => {
  const parsed = moment(timestamp);
  const diff = (moment() - parsed) / 86400000; // 1 Day diff

  if (diff < 0.5) {
    return parsed.fromNow();
  }
  if (diff < 6) {
    return parsed.calendar();
  }
  return parsed.format('MMMM D, YYYY');
};

/**
 * Returns week | month | year | X years
 */
export const getRelativeDateString = (timestamp) => {
  const parsed = moment(timestamp);
  const diff = (moment() - parsed) / 86400000; // 1 Day

  if (diff < 7) {
    return 'week';
  } if (diff < 28) {
    return 'month';
  } if (diff < 365) {
    return 'year';
  }

  const numYears = Math.floor(diff / 365);
  return `${numYears} years`;
};

export const getFormattedNumber = (number) => numeral(parseInt(number, 10)).format('0.[0]a');

export const getStaticURL = (url) => {
  if (!url) return null;
  return url
    .replace('https://exponent-content.s3.amazonaws.com', 'https://static.tryexponent.com')
    .replace('https://s3.amazonaws.com/static.tryexponent.com', 'https://static.tryexponent.com');
};

/**
 * Returns the thumbnail URL for an image if available
 * @param {*} image - an image object returned by API
 */
export const getThumbnailURL = (image) => {
  if (image) {
    if (image.formats && image.formats.thumbnail) {
      return getStaticURL(image.formats.thumbnail.url);
    }
    return getStaticURL(image.url);
  }
  return null;
};

/**
 * Returns the small URL for an image if available
 * @param {*} image - an image object returned by API
 */
export const getSmallImageURL = (image) => {
  if (image) {
    if (image.formats && image.formats.small) {
      return getStaticURL(image.formats.small.url);
    }
    return getStaticURL(image.url);
  }
  return null;
};

/**
 * Returns the medium URL for an image if available
 * @param {*} image - an image object returned by API
 */
export const getMediumImageURL = (image) => {
  if (image) {
    if (image.formats && image.formats.medium) {
      return getStaticURL(image.formats.medium.url);
    }
    return getStaticURL(image.url);
  }
  return null;
};

export const getVideoThumbnailURL = (videoID) => (
  `https://img.youtube.com/vi/${videoID}/mqdefault.jpg`
);

export const getCourseThumbnail = (course) => {
  if (!course) return null;
  return course.trailer
    ? getVideoThumbnailURL(course.trailer)
    : course.thumbnail;
};

export const getLessonThumbnail = (lesson, fallbackToCourse = true) => {
  if (!lesson) return null;
  if (lesson.image) return lesson.image;
  if (lesson.thumbnail) return lesson.thumbnail;
  if (lesson.video) return getVideoThumbnailURL(lesson.video);
  // Fall back on course thumbnail
  if (fallbackToCourse) return getCourseThumbnail(lesson.course);
  return null;
};

/**
 * Returns true if an input is a valid email and false otherwise
 * @param {string} email - inputted string
 */
export const emailRegex = (email) => {
  const regex = /^[a-zA-Z0-9.!#$%&’*+/=?^_`{|}~-]+@[a-zA-Z0-9-]+(?:\.[a-zA-Z0-9-]+)*$/;
  return regex.test(email);
};

/**
 * Simple utility that returns an absolute URL
 */
export const makeAbsoluteURL = (url) => (
  url.includes('://') || url.startsWith('//') ? url : `//${url}`
);

/**
 * Extracts error from error object if it is user friendly
 * message or will use the default message otherwise
 */
export const extractAPIError = (err = {}, defaultMessage = 'There was an unexpected error, please try again later') => {
  if (err.status < 500 && err.message) {
    return err.message;
  }
  return defaultMessage;
};

const addClass = () => {
  document.body.classList.add('using-mouse');
};

const removeClass = () => {
  document.body.classList.remove('using-mouse');
};

/**
 * Let the document know when a mouse is being used, enabling :focus and
 * :focus-within styles only when keyboard is used
 */
export const addMouseListener = () => {
  document.body.addEventListener('mousedown', addClass);
  document.body.addEventListener('keydown', removeClass);
  document.body.classList.add('using-mouse');
};

/**
 * Cleanup for addMouseListener
 */
export const removeMouseListener = () => {
  document.body.removeEventListener('mousedown', addClass);
  document.body.removeEventListener('keydown', removeClass);
};

/**
 * A11y helper for handling 'Enter' press on non-interactive elements
 */
export const onPressEnter = (cb) => (e) => {
  if (e.key === 'Enter') cb(e);
};

/**
 * Heuristic for determining e.g. whether or not to display first visit messaging
 * to a user
 * @param {object} currentUser
 */
export const isUsersFirstDay = (currentUser) => {
  if (!currentUser || !currentUser.created_at) return false;
  const oneDayInMs = 86400000;
  return (Date.now() - Date.parse(currentUser.created_at)) < oneDayInMs;
};

/**
 * @param {object} currentUser
 */
export const hasSeenInitialOnboarding = (currentUser) => {
  if (!currentUser?.profile) return false;
  return currentUser.profile.data.skippedOnboarding || currentUser.profile.data.finishedOnboarding;
};

/**
 * @param {object} currentUser
 */
export const shouldShowUniversityOnboarding = (currentUser) => currentUser?.org?.type === 'university'
&& !currentUser?.data?.finished_university_onboarding;

/**
 * @param {object} currentUser
 *
 */
export const shouldShowOnboardingVideo = (currentUser) => {
  const seenVideo = currentUser?.data?.has_seen_onboarding_video;
  return !seenVideo && isUsersFirstDay(currentUser);
};

/**
 * Returns the monthly price string based on the currency symbol and the annual plan
 * @param {string} currencySymbol currency.symbol
 * @param {number} price price of product in cents
 * @param {number} months number of months to divide by, default 12
 */
export const getMonthlyPriceStr = (currencySymbol, price, months = 12) => {
  const monthlyPrice = Math.floor(price / 100 / months);
  return `${currencySymbol}${monthlyPrice}`;
};

export const shouldShowOnboarding = (user, pathname) => (
  user
  && !user.needs_password
  && !user.profile.data.skippedOnboarding
  && !user.profile.data.finishedOnboarding
  && !pathname.includes('/onboarding')
  && !pathname.includes('coaching/onboarding')
  && !pathname.includes('/coaching/booking')
);

/**
 * Wraps the FileReader Web API to make it async/await friendly
 * @param {File} file file object to encode as a base64 string
 */
export const encodeFileAsync = (file) => (
  new Promise((resolve, reject) => {
    const reader = new FileReader();

    reader.onload = () => {
      resolve(reader.result);
    };

    reader.onerror = reject;

    reader.readAsDataURL(file);
  })
);

/**
 * Pretty print a number into hundreds, thousands, millions,
 * or billions. Always rounds down.
 * @param {number} num
 * @param {boolean} floorHundreds
 */
export const formatLargeNum = (num, floorHundreds = false) => {
  if (!Number.isInteger(num)) return '';
  if (num < 1000) return `${floorHundreds ? Math.floor(num / 100) * 100 : num}`;

  const abbrevs = {3: 'k', 6: 'm', 9: 'b'};
  let truncated = Math.floor(num / 100) / 10; // Keep one decimal place
  let tenToThe = 3;
  while (truncated) {
    if (truncated < 1000) {
      return truncated >= 100
        ? `${Math.floor(truncated)}${abbrevs[tenToThe]}`
        : `${truncated}${abbrevs[tenToThe]}`;
    }

    tenToThe += 3;
    truncated = Math.floor(truncated / 100) / 10; // Keep one decimal place
  }
  return '';
};

/**
 * Returns an object of style properties that limit the number of lines
 * of text displayed by an element.
 * @param {number} num number of lines to show
 */
export const clampLines = (num) => ({
  display: '-webkit-box',
  WebkitLineClamp: `${num}`,
  WebkitBoxOrient: 'vertical',
  overflow: 'hidden',
});

export const startsWithVowel = (str = '') => /^[aeiou]/.test(str.toLowerCase());

export const sleep = async (ms) => new Promise((resolve) => setTimeout(resolve, ms));

export const shouldHideCoaching = (user) => (
  LimitedCoachingSSOProviders.includes(user?.sso_provider) || user?.data?.hideCoaching
);

export const shouldShowUniversityCoachingBanner = (user) => (
  CoachingBannerSSOProviders.includes(user?.sso_provider)
);

/**
 * Returns the text content of a React node. Stolen from:
 * https://stackoverflow.com/a/60564620/3681279
 * @param {React.ReactNode} node
 * @returns {string}
 */
export const getNodeText = (node) => {
  if (node == null) return '';

  switch (typeof node) {
    case 'string':
    case 'number':
      return node.toString();

    case 'boolean':
      return '';

    case 'object': {
      if (node instanceof Array) { return node.map(getNodeText).join(''); }

      if ('props' in node) { return getNodeText(node.props.children); }
    } // eslint-ignore-line no-fallthrough

    default:
      console.warn('Unresolved `node` of type:', typeof node, node);
      return '';
  }
};

/**
 * New if lesson has not been read and it has been published less than 2 weeks ago
 * @param {lesson} lesson
 * @returns {boolean}
 */
export const isNewLesson = (lesson) => {
  if (!lesson?.createdAt) return false;
  const twoWeeksAgo = moment().subtract(2, 'weeks');
  const lessonCreatedAt = moment(lesson.createdAt);
  return !lesson.markedAsComplete && lessonCreatedAt.isAfter(twoWeeksAgo);
};

/**
 * New if course has been published less than 2 weeks ago
 * @param {course} course
 * @returns {boolean}
 */
export const isNewCourse = (course) => {
  if (!course?.createdAt) return false;
  const twoWeeksAgo = moment().subtract(2, 'weeks');
  const courseCreatedAt = moment(course.createdAt);
  return courseCreatedAt.isAfter(twoWeeksAgo);
};

/**
 * Doesn't support times greater than 100 hours
 * @param {number} t time in seconds
 * @returns {string} formatted time in HH:MM:SS
 */
export const formatTime = (t) => {
  const hours = Math.floor(t / 3600);
  const minutes = Math.floor((t % 3600) / 60);
  const seconds = t % 60;

  // Format each part to ensure it has two digits
  const formattedHours = String(hours).padStart(2, '0');
  const formattedMinutes = String(minutes).padStart(2, '0');
  const formattedSeconds = String(seconds).padStart(2, '0');

  return `${formattedHours}:${formattedMinutes}:${formattedSeconds}`;
};

/**
 * Returns the next match time object. Necessary because first element
 * of nextMatchTimes might be in past once tab is open for awhile.
 * @param {array} matchTimes Array of matchtime objects, as returned by getNextMatchTimes
 * @returns {object} matchTime object
*/
export const getNextMatchTime = (matchTimes) => _.find(matchTimes, (matchTimeObj) => {
  const matchTime = moment(matchTimeObj.matchTime);
  return moment().isBefore(matchTime);
});

/**
 * @param {string} sessionTime ISO 8601 string of session time
 * @returns {boolean}
 */
export const isBeforeLastMatchingOfSession = (sessionTime) => sessionTime
  && moment().isBefore(moment(sessionTime).add(_.last(TRAILING_MATCH_TIMES), 'seconds'));

/**
 * @param {string} sessionTime ISO 8601 string of session time
 * @returns {boolean}
 */
export const isAfterJoinTimeStartedForSession = (sessionTime) => sessionTime
  && moment(sessionTime).diff(moment(), 'seconds') <= LEADING_JOIN_TIME;

/**
 * @param {string} sessionTime ISO 8601 string of session time
 * @returns {boolean}
*/
export const isWithinJoinTimeForSession = (sessionTime) => (
  isAfterJoinTimeStartedForSession(sessionTime) && isBeforeLastMatchingOfSession(sessionTime)
);

/**
 * Currently defined as [8 AM, 12 AM)
 * @param {string} sessionTime ISO 8601 string of session time
 * @returns {boolean}
*/
export const isDuringUsersWakingHours = (sessionTime) => {
  const localHour = moment(sessionTime).local().hour();
  return localHour >= 8 && localHour < 24;
};

/**
 * Takes a MediaStreamTrack object from the Media Streams API
 * and returns relevant properties, so we can store for later use
 * @param {MediaStreamTrack} track
 * @returns {object}
*/
export const formatMediaStreamTrack = (track) => {
  if (!(track instanceof MediaStreamTrack)) {
    throw new Error('The provided input is not a MediaStreamTrack.');
  }

  return {
    id: track.id,
    kind: track.kind,
    label: track.label,
    enabled: track.enabled,
    muted: track.muted,
    readyState: track.readyState,
    settings: track.getSettings(),
    capabilities: track.getCapabilities?.(), // Not supported in Firefox
    constraints: track.getConstraints(),
  };
};

/**
 * Takes a NetworkInformation object from the Network Information API
 * and returns relevant properties, so we can store for later use.
 * Basically only works on Chromium browsers.
 * @param {NetworkInformation} connection
 * @returns {object}
*/
export const formatNetworkConnection = (connection) => {
  if (!connection) return connection;

  if (!(connection instanceof NetworkInformation)) {
    throw new Error('The provided input is not a NetworkInformation.');
  }

  return {
    downlink: connection.downlink,
    effectiveType: connection.effectiveType,
    rtt: connection.rtt,
    saveData: connection.saveData,
  };
};

/**
 * Get display time for a past session. Falls back on createdAt
 * for backwards compatibility, with a rough heuristic for
 * rounding to session time
 */
export const getDisplayTimeForPreviousDuetSession = (session) => {
  if (session.mode === InterviewModes.OnDemand) {
    return moment(session.createdAt).format('MMM D, YYYY h:mm A');
  }

  return session.sessionTime
    ? moment(session.sessionTime).format('MMM D, YYYY h:mm A')
    : moment(session.createdAt).add(15, 'minutes').startOf('hour').format('MMM D, YYYY h:mm A');
};

export const toDuetInterviewTypeName = (type) => InterviewTypes[type]?.name || _.startCase(type);

/**
 * Takes a Duet session and returns whether AI feedback is available
 * for that interview type
 * @param {object} session
 * @returns {boolean}
*/
export const isInterviewTypeWithoutAI = (session) => [
  InterviewTypes.Algorithms.key,
].includes(session?.type);

/**
 * Takes a Duet session and returns whether AI feedback is available
 * for that interview mode
 * @param {object} session
 * @returns {boolean}
*/
export const isInterviewModeWithoutAI = (session) => [
  InterviewModes.OnDemand,
  InterviewModes.Pramp,
].includes(session?.mode);

/**
 * Given the currentUser and a Duet session, returns whether AI
 * feedback UI elements should be shown
 * @param {object} currentUser
 * @param {object} session
 * @returns {boolean}
*/
export const hideAIFeedbackFeatures = (currentUser, session) => {
  const isUniversityUser = currentUser?.org?.type === 'university';
  const isAllowListedUniversity = currentUser?.org?.slug in AIUniversityAllowList;

  return ((isUniversityUser && !isAllowListedUniversity)
    || isInterviewTypeWithoutAI(session)
    || isInterviewModeWithoutAI(session));
};
